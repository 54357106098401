class DateTime extends Date {
  /**
   * Returns whether the given time comes before the current time.
   *
   * @example
   * DateTime.from("1982-09-20").isBefore("1982-09-19") // false
   * DateTime.from("1982-09-20").isBefore("1982-09-20") // false
   * DateTime.from("1982-09-20").isBefore("1982-09-21") // true
   */
  isBefore(date) {
    return this < new Date(date);
  }
  /**
   * Returns whether the given time comes after the current time.
   *
   * @example
   * DateTime.from("1982-09-20").isAfter("1982-09-19") // true
   * DateTime.from("1982-09-20").isAfter("1982-09-20") // false
   * DateTime.from("1982-09-20").isAfter("1982-09-21") // false
   */
  isAfter(date) {
    return this > new Date(date);
  }
  /**
   * Returns whether the given time matches the current time, optionally for the given unit (e.g., 'year', 'month', 'day', 'week').
   *
   * @example
   * DateTime.from("1982-09-20").is("1982-09-19")     // false
   * DateTime.from("1982-09-20").is("1982-09-20")     // true
   * DateTime.from("1982-09-20").is("1982-09-21")     // false
   * DateTime.from("1982-09-20").is("1982-09-21", "month") // true
   * DateTime.from("1982-09-20").is("1982-10-22", "year")  // true
   */
  is(date, unit = "") {
    const thisDate = new Date(this);
    const nextDate = new Date(date);
    switch (unit) {
      case "year":
        thisDate.setMonth(0);
        nextDate.setMonth(0);
      case "month":
        thisDate.setDate(1);
        nextDate.setDate(1);
      case "day":
        thisDate.setHours(0);
        nextDate.setHours(0);
      case "hour":
        thisDate.setMinutes(0);
        nextDate.setMinutes(0);
      case "minute":
        thisDate.setSeconds(0);
        nextDate.setSeconds(0);
      case "second":
        thisDate.setMilliseconds(0);
        nextDate.setMilliseconds(0);
    }
    return +thisDate === +nextDate;
  }
  /**
   * Returns a new DateTime from the current time with the given time added.
   *
   * @example
   * DateTime.from("1982-09-20").with(1, "day")   // DateTime 1982-09-21
   * DateTime.from("1982-09-20").with(1, "month") // DateTime 1982-10-20
   * DateTime.from("1982-09-20").with(1, "year")  // DateTime 1983-09-20
   */
  with(number, unit) {
    const datetime = new DateTime(this);
    if (unit === "week") {
      datetime.setDate(this.getDate() + number * 7);
    } else if (Object.hasOwn(_mapOfDateFns, unit)) {
      datetime["set" + _mapOfDateFns[unit]](
        datetime["get" + _mapOfDateFns[unit]]() + number
      );
    } else {
      datetime.setTime(this.getTime() + number);
    }
    return datetime;
  }
  /**
   * Returns a relative time string describing the time passed since the given time until the current time.
   *
   * @example
   * DateTime.from("1982-09-20").since("1983-09-06") // "1 year ago"
   * DateTime.from("1982-09-20").since()             // "42 years ago"
   */
  since(time = Date.now(), options) {
    if (_isInvalidDate(this)) return "Invalid DateTime";
    const { locale, unit, ...format } = {
      locale: DateTime.locale,
      timeZone: DateTime.timeZone,
      ...Object(options)
    };
    const rtf = new Intl.RelativeTimeFormat(locale, { ...format });
    const delta = new Date(time) - new Date(this);
    for (const [_unit, _ms] of _formats) {
      const value = Math.round(delta / _ms);
      if (unit === _unit || !unit && value !== 0) {
        return rtf.format(-value, _unit);
      }
    }
    return rtf.format(-Math.floor(delta / 1e3), "second");
  }
  /**
   * Returns a relative time string describing the time left since the current time until the given time.
   *
   * @example
   * DateTime.from("1982-09-20").until("1983-09-06")
   * // "in 1 year"
   *
   * DateTime.from("1982-09-20").until()
   * // "in 42 years"
   */
  until(time = Date.now(), options) {
    return new DateTime(time).since(this, options);
  }
  toParts(options = {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short"
  }) {
    if (_isInvalidDate(this)) return [];
    const { locale, ...format } = {
      locale: DateTime.locale,
      timeZone: DateTime.timeZone,
      ...Object(options)
    };
    const formatter = new Intl.DateTimeFormat(locale, format);
    return formatter.formatToParts(this).map(
      _formatTimePart({
        ...formatter.resolvedOptions(),
        ...format
      })
    );
  }
  /**
   * Returns a string representing the current time.
   *
   * @example
   * DateTime.from("1982-09-20").toString()
   * // "Sunday, September 19, 1982 at 8:00 PM EDT" // en-US & America/New_York
   * // "Monday 20 September 1982 at 1:00 BST"      // en-GB" & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ dateStyle: "full" })
   * // "Sunday, September 19, 1982" // en-US & America/New_York
   * // "Monday 20 September 1982"   // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ dateStyle: "medium" })
   * // "Sep 19, 1982" // en-US & America/New_York
   * // "20 Sept 1982" // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ dateStyle: "short" })
   * // "9/19/82"    // en-US & America/New_york
   * // "20/09/1982" // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "full" })
   * // "8:00:00 PM Eastern Daylight Time" // en-US & America/New_York
   * // "01:00:00 British Summer Time"     // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "long" })
   * // "8:00:00 PM EDT" // en-US & America/New_York
   * // "8:00:00 PM"     // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "medium" })
   * // "2:40:50 PM" // en-US & America/New_York
   * // "01:00:00"   // en-GB & Europe/London
   *
   * DateTime.from("1982-09-20").toString({ timeStyle: "short" })
   * // "8:00 PM" // en-US & America/New_York
   * // "1:00"    // en-GB & Europe/London
   */
  toString(options) {
    if (_isInvalidDate(this)) return "Invalid DateTime";
    return this.toParts(options).map((part) => part.value).join("");
  }
  /**
   * Returns a string representing the current time in Local Time Zone (LTZ) in the date time string format.
   *
   * @example
   * date.toLTZString()       // "1982-09-20T00:00:00.000"
   * date.toLTZString("date") // "1982-09-20"
   * date.toLTZString("time") // "00:00"
   */
  toLTZString(unit) {
    const date = String(this.getFullYear()).padStart(4, "0") + "-" + String(this.getMonth()).padStart(2, "0") + "-" + String(this.getDate()).padStart(2, "0");
    const time = String(this.getHours()).padStart(2, "0") + ":" + String(this.getMinutes()).padStart(2, "0");
    const secs = String(this.getSeconds()).padStart(2, "0") + "." + String(this.getMilliseconds()).padStart(3, "0");
    return unit === "date" ? date : unit === "time" ? time : date + "T" + time + ":" + secs;
  }
  /**
   * Returns the time for the current date since the epoch.
   *
   * @example
   * date.getTime()         // 401328000000
   * date.getTime("second") //    401328000
   * date.getTime("day")    //         4645
   * date.getTime("month")  //          152
   */
  getTime(unit) {
    return unit === "year" ? this.getUTCFullYear() - 1970 : unit === "month" ? (this.getUTCFullYear() - 1970) * 12 + this.getUTCMonth() : unit === "week" ? Math.floor(+this / (1e3 * 60 * 60 * 24 / 7)) : unit === "day" ? Math.floor(+this / (1e3 * 60 * 60 * 24)) : unit === "second" ? Math.floor(+this / 1e3) : +this;
  }
  static from(...args) {
    return new DateTime(...args);
  }
  /**
   * Returns a relative time string describing the time left since the given time until the present time.
   *
   * @example
   * DateTime.since("1982-09-20") // "in 42 years"
   * DateTime.since("2082-09-20") // "58 years ago"
   */
  static since(time, options) {
    return DateTime.from().since(time, options);
  }
  /**
   * Returns a relative time string describing the time left since the present time until the given time.
   *
   * @example
   * DateTime.until("1982-09-20") // "42 years ago"
   * DateTime.until("2082-09-20") // "in 58 years"
   */
  static until(time, options) {
    return new DateTime(time).since(Date.now(), options);
  }
  /**
   * Returns whether the given time comes after the present time.
   *
   * @example
   * DateTime.isAfter("1982-09-20") // true
   * DateTime.isAfter("9982-09-20") // false
   */
  static isBefore(date) {
    return DateTime.from().isBefore(date);
  }
  /**
   * Returns whether the given time comes after the present time.
   *
   * @example
   * DateTime.isBefore("1982-09-20") // false
   * DateTime.isBefore("9982-09-20") // true
   */
  static isAfter(date) {
    return DateTime.from().isAfter(date);
  }
  /**
   * Returns a new DateTime of the present time with the given time added.
   *
   * @example
   * // were the date 1982-09-20
   * DateTime.with(1, "day")   // DateTime 1982-09-21
   * DateTime.with(1, "month") // DateTime 1982-10-20
   * DateTime.with(1, "year")  // DateTime 1983-09-20
   */
  static with(number, unit) {
    return DateTime.from().with(number, unit);
  }
  /**
   * Returns a string representing the present time.
   *
   * @example
   * DateTime.toString()
   * // "Sunday, September 19, 1982 at 8:00 PM EDT" // en-US & America/New_York
   * // "Monday 20 September 1982 at 1:00 BST"      // en-GB" & Europe/London
   *
   * DateTime.toString({ dateStyle: "full" })
   * // "Sunday, September 19, 1982" // en-US & America/New_York
   * // "Monday 20 September 1982"   // en-GB & Europe/London
   *
   * DateTime.toString({ dateStyle: "medium" })
   * // "Sep 19, 1982" // en-US & America/New_York
   * // "20 Sept 1982" // en-GB & Europe/London
   *
   * DateTime.toString({ dateStyle: "short" })
   * // "9/19/82"    // en-US & America/New_york
   * // "20/09/1982" // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "full" })
   * // "8:00:00 PM Eastern Daylight Time" // en-US & America/New_York
   * // "01:00:00 British Summer Time"     // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "long" })
   * // "8:00:00 PM EDT" // en-US & America/New_York
   * // "8:00:00 PM"     // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "medium" })
   * // "2:40:50 PM" // en-US & America/New_York
   * // "01:00:00"   // en-GB & Europe/London
   *
   * DateTime.toString({ timeStyle: "short" })
   * // "8:00 PM" // en-US & America/New_York
   * // "1:00"    // en-GB & Europe/London
   */
  static toString(options) {
    return DateTime.from().toString(options);
  }
  /** BCP 47 Language tag. */
  static locale = new Intl.DateTimeFormat().resolvedOptions().locale;
  /** IANA time zone name. */
  static timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
}
const _isInvalidDate = isNaN;
const _isNumeric = RegExp.prototype.test.bind(/^\d+$/);
const _formatTimePart = (options) => (part) => ({
  type: part.type,
  value: (
    // 2-digit hour
    part.type === "hour" && options.hour === "2-digit" && _isNumeric(part.value) ? String(Number(part.value)).padStart(2, "0") : (
      // numeric hour
      part.type === "hour" && (options.hour === "numeric" || options.timeStyle === "short") && _isNumeric(part.value) ? String(Number(part.value)) : (
        // 2-digit minute
        part.type === "minute" && options.minute === "2-digit" && _isNumeric(part.value) ? String(Number(part.value)).padStart(2, "0") : (
          // numeric minute
          part.type === "minute" && options.minute === "numeric" && _isNumeric(part.value) ? String(Number(part.value)) : (
            // 2-digit second
            part.type === "second" && options.second === "2-digit" && _isNumeric(part.value) ? String(Number(part.value)).padStart(2, "0") : (
              // numeric second
              part.type === "second" && options.second === "numeric" && _isNumeric(part.value) ? String(Number(part.value)) : (
                // any other value
                part.value
              )
            )
          )
        )
      )
    )
  )
});
const _mapOfDateFns = {
  year: "FullYear",
  month: "Month",
  day: "Date",
  hour: "Hours",
  minute: "Minutes",
  second: "Seconds",
  millisecond: "Time"
};
const _formats = [
  ["year", 31536e6],
  ["month", 2592e6],
  ["week", 6048e5],
  ["day", 864e5],
  ["hour", 36e5],
  ["minute", 6e4]
];

export { DateTime };
